<template>
  <div>
    <pro-document-breadcrumbs
      :breadcrumbs="[{ name: 'menu.SystemSetting', icon: 'PROSmart-Setting' }]"
      user-role="procurer"
    ></pro-document-breadcrumbs>

    <q-card style="margin-top: 10px">
      <q-tab-panel name="system" class="scroll">
        <!--    <h3 style="margin: 1rem 0 0 1rem">-->
        <!--      {{ getRes("Form.Option.Search") }}{{ getRes("menu.ModuleSetting") }}-->
        <!--    </h3>-->
        <q-card
          flat
          bordered
          v-for="section in data"
          :key="section.name"
          style="margin-bottom: 10px"
        >
          <q-card-section style="padding-left: 0; padding-right: 0">
            <div
              class="text-h4"
              v-text="section.name"
              style="padding: 0 16px 16px 16px"
            />

            <q-markup-table flat>
              <tbody>
                <tr v-for="item in section.value" :key="item">
                  <td class="text-left" style="width: 80%">
                    <div
                      class="text-h5"
                      style="font-weight: bolder"
                      v-text="item.name"
                    />
                    <div class="text-h6" v-html="item.description" />
                  </td>
                  <td style="width: 20%">
                    <q-input
                      v-if="!item.type || item.type === 'string'"
                      :dense="true"
                      class="col-2"
                      outlined
                      v-model="item.value"
                    />
                    <q-select
                      v-if="
                        item.type &&
                        item.type.indexOf('|') !== -1 &&
                        item.type.indexOf('||') === -1
                      "
                      outlined
                      class="col-2"
                      :dense="true"
                      v-model="item.value"
                      :options="typeSplit(item.type)"
                    />
                    <template
                      v-if="item.type && item.type.indexOf('||') !== -1"
                    >
                      <q-option-group
                        class="col-2"
                        :options="optionGroup(item.type)"
                        type="checkbox"
                        v-model="item.value"
                      />
                    </template>
                    <el-input-number
                      v-if="item.type && item.type === 'number'"
                      class="col-2"
                      v-model="item.value"
                    />
                    <q-toggle
                      dense
                      v-if="item.type && item.type === 'boolean'"
                      style="padding-bottom: 16px"
                      v-model="item.value"
                    />
                    <pro-json-setting
                      v-if="item.type && item.type === 'json'"
                      v-model="item.value"
                    />
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
        </q-card>
      </q-tab-panel>
    </q-card>
    <div class="row justify-end">
      <div class="col-auto">
        <q-btn
          @click="Save"
          style="margin-top: 16px; margin-bottom: 16px"
          color="primary"
          no-caps
          :label="getRes('System.Button.Save')"
        ></q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProJsonSetting from "@/components/PROSmart/Setting/ProJsonSetting.vue";

export default {
  components: { ProJsonSetting, ProDocumentBreadcrumbs },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    typeSplit(type) {
      return type.split("|");
    },
    typeSplits(type) {
      return type.split("||");
    },
    optionGroup(arrayString) {
      return arrayString.split("||").map((b) => {
        return { label: b, value: b };
      });
    },
    arrayGroupBy(array, groupParam) {
      const groupedArr = array.reduce((pre, item) => {
        const groupName = item[groupParam];
        pre[groupName] = [...(pre[groupName] || []), item];
        return pre;
      }, {});
      this.data = Object.keys(groupedArr).map((key) => {
        return {
          name: key,
          value: groupedArr[key],
        };
      });
    },
    updateObj(oldVal, newVal, arr) {
      for (var i in arr) {
        for (var j in arr[i]) {
          if (j === oldVal) {
            arr[i][newVal] = arr[i][j];
            delete arr[i][oldVal];
          }
        }
      }
      return arr;
    },
    getSetting() {
      this.$proSmart.setting.getSystemSetting(this, "").then((rs) => {
        this.data = rs;
        for (let i in this.data) {
          if (this.data[i].value === "True") {
            this.data[i].value = true;
          }
          if (this.data[i].value === "False") {
            this.data[i].value = false;
          }
          if (this.data[i].type && this.data[i].type.indexOf("||") !== -1) {
            this.data[i].value = this.data[i].value.split(",");
          }
        }
        this.arrayGroupBy(this.data, "sectionName");
      });
    },
    Save() {
      const mirror_data = JSON.parse(JSON.stringify(this.data));
      let ready_data = [];
      mirror_data.forEach((items) => {
        items.value.forEach((item) => {
          ready_data.push(item);
        });
      });

      for (let i in ready_data) {
        for (let a in ready_data[i]) {
          if (ready_data[i][a] === null) {
            ready_data[i][a] += "";
          }
        }
        if (typeof ready_data[i].value === "number") {
          ready_data[i].value = ready_data[i].value.toString();
        }
        if (typeof ready_data[i].value === "boolean") {
          ready_data[i].value = ready_data[i].value.toString();
          const a = ready_data[i].value.charAt(0).toUpperCase();
          ready_data[i].value = a + ready_data[i].value.slice(1);
        }
        if (ready_data[i].type && ready_data[i].type.indexOf("||") !== -1) {
          ready_data[i].value = ready_data[i].value.join(",");
        }
      }

      this.updateObj(
        this.updateObj("description", "KeyDescription", ready_data)
      );
      this.updateObj(this.updateObj("value", "KeyValue", ready_data));
      this.updateObj(this.updateObj("name", "KeyName", ready_data));
      this.updateObj(this.updateObj("type", "KeyType", ready_data));

      const SystemInfo = {
        systemList: ready_data,
      };
      this.$proSmart.setting
        .updateSystemSettingList(this, JSON.stringify(SystemInfo))
        .then(() => {
          this.$alert(
            this.$t("WorkflowOperation.OperationSucceed"),
            this.$t("notification"),
            { confirmButtonText: this.getRes("ok") }
          );
        });
    },
  },
  watch: {
    tab: {
      handler(New) {
        this.getSetting(New);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  .high-light {
    color: #04b21e;
    font-weight: bolder;
  }
}
</style>
